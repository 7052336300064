<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <div class="fz-20 mb-20">退款审核完成后，金额会返还给用户的支付账户。</div>
    <template #footer>
      <el-button @click.stop="handleClose">拒绝</el-button>
      <el-button type="primary" @click="handleConfirmRefund">
        确认退款
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance } from 'vue'
  import { reqRefundAudit } from '@/api/exhibitionManage'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '退款审核',
    },
  })
  const dialogFormVisible = ref(false)
  const orderNo = ref(null)
  const emit = defineEmits(['noticeRefresh'])
  const { proxy } = getCurrentInstance()
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  const handleOpen = (params = null) => {
    orderNo.value = params
    dialogFormVisible.value = true
  }
  const handleConfirmRefund = debounce(async () => {
    if (!orderNo.value) return
    try {
      let resp = await reqRefundAudit(orderNo.value)
      proxy.$baseMessage(`退款成功`, 'success', 'vab-hey-message-success')
      emit('noticeRefresh')
    } catch (err) {
      proxy.$baseMessage(`退款失败`, 'error', 'vab-hey-message-error')
    } finally {
      dialogFormVisible.value = false
    }
  })
  defineExpose({
    handleClose,
    handleOpen,
    handleConfirmRefund,
  })
</script>
